// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisory-agreement-js": () => import("./../../../src/pages/advisory-agreement.js" /* webpackChunkName: "component---src-pages-advisory-agreement-js" */),
  "component---src-pages-brochure-supplement-adv-part-2-b-js": () => import("./../../../src/pages/brochure-supplement-adv-part-2b.js" /* webpackChunkName: "component---src-pages-brochure-supplement-adv-part-2-b-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-form-crs-adv-part-3-js": () => import("./../../../src/pages/form-crs-adv-part-3.js" /* webpackChunkName: "component---src-pages-form-crs-adv-part-3-js" */),
  "component---src-pages-form-crs-conversation-starters-js": () => import("./../../../src/pages/form-crs-conversation-starters.js" /* webpackChunkName: "component---src-pages-form-crs-conversation-starters-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-disclaimers-js": () => import("./../../../src/pages/legal-disclaimers.js" /* webpackChunkName: "component---src-pages-legal-disclaimers-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-wrap-fee-brochure-adv-part-2-a-js": () => import("./../../../src/pages/wrap-fee-brochure-adv-part-2a.js" /* webpackChunkName: "component---src-pages-wrap-fee-brochure-adv-part-2-a-js" */)
}

